import { SelectionChangedEvent } from "ag-grid-community";
import { MarshallingTypeExclusionEntity } from "domain/entity/MarshallingTypeExclusion/MarshallingTypeExclusionEntity";
import { INITIAL_MARL_TYPE_EXCL_COMP_COL_DEF, transferRowData } from "presentation/constant/MarshallingTypeExclusion/MarshallingTypeExclusionColumnDefinition";
import { useMarshallingTypeExclusionVM } from "presentation/hook/MarshallingTypeExclusion/useMarshallingTypeExclusionVM";
import { useMarshallingTypeExclusionTracked } from "presentation/store/MarshallingTypeExclusion/MarshallingTypeExclusionProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";

const MarshallingTypeExclusionTablePanel:React.FC = () => {
    const [criteriaState] = useMarshallingTypeExclusionTracked();
    const criteriaVM = useMarshallingTypeExclusionVM();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    
    let gridRef: any = useRef();

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_MARL_TYPE_EXCL_COMP_COL_DEF.slice());
            
        gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const handleRowDoubleClick = useCallback((consGrpComp: MarshallingTypeExclusionEntity) => {
        criteriaVM.onRowDoubleClick(consGrpComp);
    }, [criteriaVM])


    const handleAddClick = useCallback(async() => {
        criteriaVM.onAdd();      
    },[criteriaVM])
    
    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        criteriaVM.updateSelectedComponentRows(selectedRows);
    }, [criteriaVM])

    const memoCriteriaComponentTable = useMemo(() => {
        return <HPHTable
            id='CriteriaComponent-table'          
            columns={INITIAL_MARL_TYPE_EXCL_COMP_COL_DEF.slice()}
            data={transferRowData(criteriaState.criteriaEntityList??[])}
            showPaginator={false}
            editable={false}
            showAddIcon={!criteriaState.masterState.isSliderOpen}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            //rowSelection={"multiple"}
            onAddClick={handleAddClick}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any) => handleRowDoubleClick(e.data)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 100px)"
            ref={gridRef} />;
    }, [criteriaState, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    return <>
    { <TableWrapper>{memoCriteriaComponentTable}</TableWrapper>}
    </>;
}

export default memo(MarshallingTypeExclusionTablePanel);